import Scrollspy from "react-scrollspy"
import React from "react"

const handleClick = session => {
  const handleGoto = e => {
    e.preventDefault()
    const where = document.getElementById(encodeURI(session.slice(9)))
    if (!where) {
      return
    }
    window.scrollTo({
      top: where.offsetTop - 80,
      behavior: "smooth",
    })
  }
  return handleGoto
}

const TOC = ({ dataset, clss }) => {
  return (
    <Scrollspy
      items={dataset.map(({ session }) => encodeURI(session.slice(9)))}
      className={clss.scroll}
      currentClassName={clss.currentScroll}
      offset={-100}
    >
      {dataset.map(({ session }) => (
        <li key={`toc-${session}`}>
          <a
            role="button"
            aria-label="Go to Session"
            href={`#${encodeURI(session.slice(9))}`}
            title={`Go to ${session.slice(9)}`}
            onClick={handleClick(session)}
            children={session.slice(9)}
          />
        </li>
      ))}
    </Scrollspy>
  )
}

export default TOC
